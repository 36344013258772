<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        profileSalary.perfilSalarialId == 0
          ? $t("Routes.ProfileSalaryAdd")
          : $t("Routes.ProfileSalaryEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileSalary.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileSalary.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileSalary.nombre"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileSalary.columns.baseSalary')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileSalary.columns.baseSalary')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileSalary.sueldoBase"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileSalary.columns.benefits')"
            >
              <b-form-group
                :label="$t('humanResources.profileSalary.columns.benefits')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileSalary.prestaciones"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileSalary.columns.varSalary')"
            >
              <b-form-group
                :label="$t('humanResources.profileSalary.columns.varSalary')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileSalary.sueldoVariable"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-checkbox
              id="checkbox-1"
              v-model="profileSalary.tienePrestacionesDeLey"
              name="haveBenefit"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('humanResources.profileSalary.columns.haveBenefits') }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileSalary.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="profileSalary.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingProfileSalary" small class="mr-1" />
            {{ profileSalary.perfilSalarialId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BFormInvalidFeedback,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalCall: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const addingProfileSalary = ref(false)
    const profileSalary = ref({
      perfilSalarialId: 0,
      nombre: '',
      descripcion: '',
      sueldoBase: null,
      prestaciones: null,
      sueldoVariable: null,
      tienePrestacionesDeLey: false,
      archivado: false,
    })

    const blankProfileSalary = {
      perfilSalarialId: 0,
      nombre: '',
      descripcion: '',
      sueldoBase: null,
      prestaciones: null,
      sueldoVariable: null,
      tienePrestacionesDeLey: false,
      archivado: false,
    }

    const {
      fetchProfileSalary,
      createProfileSalary,
      updateProfileSalary,
    } = humanResources()

    const resetProfileSalary = () => {
      profileSalary.value = JSON.parse(JSON.stringify(blankProfileSalary))
    }

    if (router.currentRoute.params.perfilSalarialId) {
      fetchProfileSalary(router.currentRoute.params.perfilSalarialId, data => {
        profileSalary.value = data
      })
    } else {
      profileSalary.value = JSON.parse(JSON.stringify(blankProfileSalary))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProfileSalary)

    const onSubmit = () => {
      addingProfileSalary.value = true
      if (profileSalary.value.perfilSalarialId === 0) {
        createProfileSalary(profileSalary.value, () => {
          addingProfileSalary.value = false
          if (props.modalCall) {
            emit('find-records')
          } else {
            router.push({ name: 'apps-human-resources-profile-salary-list' })
          }
        })
      } else {
        updateProfileSalary(profileSalary.value.perfilSalarialId, profileSalary.value, () => {
          addingProfileSalary.value = false
          router.push({ name: 'apps-human-resources-profile-salary-list' })
        })
      }
    }

    return {
      profileSalary,
      addingProfileSalary,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
    }
  },
}
</script>
